// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.App_App__B2Ebb {
  position: relative;
}

.App_Section__8vpZk {
  padding: 0 3rem;
  background-color: transparent;
  overflow: hidden;
  margin: auto;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
}

@media only screen and (max-width: 425px) {
  .App_Section__8vpZk {
    padding: 0 .2rem;
  }
}`, "",{"version":3,"sources":["webpack://./src/App.module.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;AACpB;;AAEA;EACE,eAAe;EACf,6BAA6B;EAC7B,gBAAgB;EAChB,YAAY;EACZ,kBAAkB;EAClB,aAAa;EACb,sBAAsB;EACtB,mBAAmB;AACrB;;AAEA;EACE;IACE,gBAAgB;EAClB;AACF","sourcesContent":[".App {\n  position: relative;\n}\n\n.Section {\n  padding: 0 3rem;\n  background-color: transparent;\n  overflow: hidden;\n  margin: auto;\n  position: relative;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n}\n\n@media only screen and (max-width: 425px) {\n  .Section {\n    padding: 0 .2rem;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"App": `App_App__B2Ebb`,
	"Section": `App_Section__8vpZk`
};
export default ___CSS_LOADER_EXPORT___;
