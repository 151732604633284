// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.NavBar_Section__z3FVB {
    background-color: transparent;
    margin: auto;
    position: relative;
    display: flex;
    align-items: center;
    min-height: 1rem;
}

.NavBar_Brand__tynFz {
    font-family: 'Gochi Hand', cursive;
    font-size: 2rem;
    color: #F0852D;
    text-decoration: none;
    transition: transform 0.2s ease-in-out, color 0.2s;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);
}

.NavBar_Brand__tynFz:hover {
    color: #ea9753;
    transform: scale(1.1);
}

.NavBar_Brand__tynFz:active {
    transform: scale(1.05);
}

.NavBar_Brand__tynFz:focus {
    color: #F0852D;
    outline: none;
}

.NavBar_Hamburger__Rg-v\\+ {
    border: none;
    position: absolute;
    top: 0;
    right: 0;
    cursor: pointer;
}

.NavBar_Hamburger__Rg-v\\+ span {
    width: 15px;
    cursor: pointer;
    border: none;
}

.NavBar_Hamburger__Rg-v\\+:active span,
.NavBar_Hamburger__Rg-v\\+:focus span {
    border: none;
}

@media only screen and (max-width: 425px) {
    .NavBar_Brand__tynFz {
        font-size: 1rem;
    }
}`, "",{"version":3,"sources":["webpack://./src/styles/NavBar.module.css"],"names":[],"mappings":"AAAA;IACI,6BAA6B;IAC7B,YAAY;IACZ,kBAAkB;IAClB,aAAa;IACb,mBAAmB;IACnB,gBAAgB;AACpB;;AAEA;IACI,kCAAkC;IAClC,eAAe;IACf,cAAc;IACd,qBAAqB;IACrB,kDAAkD;IAClD,2CAA2C;AAC/C;;AAEA;IACI,cAAc;IACd,qBAAqB;AACzB;;AAEA;IACI,sBAAsB;AAC1B;;AAEA;IACI,cAAc;IACd,aAAa;AACjB;;AAEA;IACI,YAAY;IACZ,kBAAkB;IAClB,MAAM;IACN,QAAQ;IACR,eAAe;AACnB;;AAEA;IACI,WAAW;IACX,eAAe;IACf,YAAY;AAChB;;AAEA;;IAEI,YAAY;AAChB;;AAEA;IACI;QACI,eAAe;IACnB;AACJ","sourcesContent":[".Section {\n    background-color: transparent;\n    margin: auto;\n    position: relative;\n    display: flex;\n    align-items: center;\n    min-height: 1rem;\n}\n\n.Brand {\n    font-family: 'Gochi Hand', cursive;\n    font-size: 2rem;\n    color: #F0852D;\n    text-decoration: none;\n    transition: transform 0.2s ease-in-out, color 0.2s;\n    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);\n}\n\n.Brand:hover {\n    color: #ea9753;\n    transform: scale(1.1);\n}\n\n.Brand:active {\n    transform: scale(1.05);\n}\n\n.Brand:focus {\n    color: #F0852D;\n    outline: none;\n}\n\n.Hamburger {\n    border: none;\n    position: absolute;\n    top: 0;\n    right: 0;\n    cursor: pointer;\n}\n\n.Hamburger span {\n    width: 15px;\n    cursor: pointer;\n    border: none;\n}\n\n.Hamburger:active span,\n.Hamburger:focus span {\n    border: none;\n}\n\n@media only screen and (max-width: 425px) {\n    .Brand {\n        font-size: 1rem;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Section": `NavBar_Section__z3FVB`,
	"Brand": `NavBar_Brand__tynFz`,
	"Hamburger": `NavBar_Hamburger__Rg-v+`
};
export default ___CSS_LOADER_EXPORT___;
