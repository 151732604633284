// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.LandingPage_Section__uAWGY {
    height: 95vh;
}

.LandingPage_Row__RSJFU {
    height: 100%;
}

.LandingPage_Button1__F5zwN,
.LandingPage_Button2__zj4hN,
.LandingPage_Button3__ckDfs {
    color: #fff;
    background-color: #2a9d8f;
    transition: background-color 0.3s, transform 0.3s;
    font-size: 1.5rem;
    font-weight: 600;
    border-radius: 25px;
    width: 240px;
    padding: 12px 20px;
    border: none;
    cursor: pointer;
    box-shadow: 0 4px 6px rgba(255, 159, 124, 0.1);
}

.LandingPage_Button1__F5zwN:hover,
.LandingPage_Button2__zj4hN:hover,
.LandingPage_Button3__ckDfs:hover {
    background-color: #33C1B1;
}

.LandingPage_Button1__F5zwN:active,
.LandingPage_Button2__zj4hN:active,
.LandingPage_Button3__ckDfs:active {
    background-color: #1A6158;
    transform: translateY(2px);
    box-shadow: 0 2px 4px rgba(255, 90, 59, 0.2);
}`, "",{"version":3,"sources":["webpack://./src/styles/LandingPage.module.css"],"names":[],"mappings":"AAAA;IACI,YAAY;AAChB;;AAEA;IACI,YAAY;AAChB;;AAEA;;;IAGI,WAAW;IACX,yBAAyB;IACzB,iDAAiD;IACjD,iBAAiB;IACjB,gBAAgB;IAChB,mBAAmB;IACnB,YAAY;IACZ,kBAAkB;IAClB,YAAY;IACZ,eAAe;IACf,8CAA8C;AAClD;;AAEA;;;IAGI,yBAAyB;AAC7B;;AAEA;;;IAGI,yBAAyB;IACzB,0BAA0B;IAC1B,4CAA4C;AAChD","sourcesContent":[".Section {\n    height: 95vh;\n}\n\n.Row {\n    height: 100%;\n}\n\n.Button1,\n.Button2,\n.Button3 {\n    color: #fff;\n    background-color: #2a9d8f;\n    transition: background-color 0.3s, transform 0.3s;\n    font-size: 1.5rem;\n    font-weight: 600;\n    border-radius: 25px;\n    width: 240px;\n    padding: 12px 20px;\n    border: none;\n    cursor: pointer;\n    box-shadow: 0 4px 6px rgba(255, 159, 124, 0.1);\n}\n\n.Button1:hover,\n.Button2:hover,\n.Button3:hover {\n    background-color: #33C1B1;\n}\n\n.Button1:active,\n.Button2:active,\n.Button3:active {\n    background-color: #1A6158;\n    transform: translateY(2px);\n    box-shadow: 0 2px 4px rgba(255, 90, 59, 0.2);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Section": `LandingPage_Section__uAWGY`,
	"Row": `LandingPage_Row__RSJFU`,
	"Button1": `LandingPage_Button1__F5zwN`,
	"Button2": `LandingPage_Button2__zj4hN`,
	"Button3": `LandingPage_Button3__ckDfs`
};
export default ___CSS_LOADER_EXPORT___;
