// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.CurrentWeather_RowContainer__2qKSe {
    height: 100%;
}

.CurrentWeather_ConditionsContainer__W5K2q {
    width: 100%;
    max-width: 675px;
}

.CurrentWeather_Conditions__erkGn {
    flex: 1.5 0;
    font-weight: 500;
    font-size: 1rem;
    text-align: left;
}

@media only screen and (min-height: 1000px) {
    .CurrentWeather_Section__XPuUv {
        padding-top: 5rem;
    }
}

@media only screen and (max-width: 425px) {
    .CurrentWeather_Conditions__erkGn {
        font-size: 0.8rem;
    }
}

/* 
@media only screen and (max-height: 590px) {
    .Icon {
        height: 70px;
    }

    .Conditions {
        font-weight: 600;
        font-size: 1rem;
    }
} */`, "",{"version":3,"sources":["webpack://./src/styles/CurrentWeather.module.css"],"names":[],"mappings":"AAAA;IACI,YAAY;AAChB;;AAEA;IACI,WAAW;IACX,gBAAgB;AACpB;;AAEA;IACI,WAAc;IACd,gBAAgB;IAChB,eAAe;IACf,gBAAgB;AACpB;;AAEA;IACI;QACI,iBAAiB;IACrB;AACJ;;AAEA;IACI;QACI,iBAAiB;IACrB;AACJ;;AAEA;;;;;;;;;;GAUG","sourcesContent":[".RowContainer {\n    height: 100%;\n}\n\n.ConditionsContainer {\n    width: 100%;\n    max-width: 675px;\n}\n\n.Conditions {\n    flex: 1.5 0 0%;\n    font-weight: 500;\n    font-size: 1rem;\n    text-align: left;\n}\n\n@media only screen and (min-height: 1000px) {\n    .Section {\n        padding-top: 5rem;\n    }\n}\n\n@media only screen and (max-width: 425px) {\n    .Conditions {\n        font-size: 0.8rem;\n    }\n}\n\n/* \n@media only screen and (max-height: 590px) {\n    .Icon {\n        height: 70px;\n    }\n\n    .Conditions {\n        font-weight: 600;\n        font-size: 1rem;\n    }\n} */"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"RowContainer": `CurrentWeather_RowContainer__2qKSe`,
	"ConditionsContainer": `CurrentWeather_ConditionsContainer__W5K2q`,
	"Conditions": `CurrentWeather_Conditions__erkGn`,
	"Section": `CurrentWeather_Section__XPuUv`
};
export default ___CSS_LOADER_EXPORT___;
