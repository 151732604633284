// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Search_InputContainer__ZKlLu {
    width: 65vw;
}

.Search_InputContainer__ZKlLu input:focus {
    box-shadow: none;
}

.Search_InputSearch__v-gup {
    border: none;
    border-radius: 0;
    background-color: #fff;
    padding: 0.1rem 0.75rem;
}

.Search_Input__aHpSb {
    flex: 1 1;
    width: auto;
    text-align: left;
    font-size: .9rem;
    padding: .5rem 0.5rem;
    border: none;
    border-width: 0.5px;
}


@media only screen and (max-width: 464px) {
    .Search_InputSearch__v-gup {
        font-size: 0.7rem;
    }
}

@media only screen and (max-width: 464px) {
    .Search_Input__aHpSb {
        width: 50%;
    }
}`, "",{"version":3,"sources":["webpack://./src/styles/Search.module.css"],"names":[],"mappings":"AAAA;IACI,WAAW;AACf;;AAEA;IACI,gBAAgB;AACpB;;AAEA;IACI,YAAY;IACZ,gBAAgB;IAChB,sBAAsB;IACtB,uBAAuB;AAC3B;;AAEA;IACI,SAAO;IACP,WAAW;IACX,gBAAgB;IAChB,gBAAgB;IAChB,qBAAqB;IACrB,YAAY;IACZ,mBAAmB;AACvB;;;AAGA;IACI;QACI,iBAAiB;IACrB;AACJ;;AAEA;IACI;QACI,UAAU;IACd;AACJ","sourcesContent":[".InputContainer {\n    width: 65vw;\n}\n\n.InputContainer input:focus {\n    box-shadow: none;\n}\n\n.InputSearch {\n    border: none;\n    border-radius: 0;\n    background-color: #fff;\n    padding: 0.1rem 0.75rem;\n}\n\n.Input {\n    flex: 1;\n    width: auto;\n    text-align: left;\n    font-size: .9rem;\n    padding: .5rem 0.5rem;\n    border: none;\n    border-width: 0.5px;\n}\n\n\n@media only screen and (max-width: 464px) {\n    .InputSearch {\n        font-size: 0.7rem;\n    }\n}\n\n@media only screen and (max-width: 464px) {\n    .Input {\n        width: 50%;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"InputContainer": `Search_InputContainer__ZKlLu`,
	"InputSearch": `Search_InputSearch__v-gup`,
	"Input": `Search_Input__aHpSb`
};
export default ___CSS_LOADER_EXPORT___;
