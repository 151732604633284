// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.HistoricalWeather_ConditionsContainer__FjX0J {
    width: auto;
    align-items: center;
}

.HistoricalWeather_Conditions__W9zOv {
    font-weight: 600;
    font-size: 1rem;
}

.HistoricalWeather_InputContainer__13Tdf {
    width: 19rem;
    justify-content: center;
}

.HistoricalWeather_InputSearch__L6jFR {
    border: none;
    background-color: #fff;
}

.HistoricalWeather_Input__CMhXo {
    text-align: center;
    padding-left: 0.1rem;
    border: none;
    border-radius: 10px;
    border-width: 0.5px;
}

.HistoricalWeather_InputDate__EIdN7 {
    padding: .3rem .1rem;
}

.HistoricalWeather_ResetButton__Q7z8b,
.HistoricalWeather_ResetButton__Q7z8b:visited,
.HistoricalWeather_ResetButton__Q7z8b:hover,
.HistoricalWeather_ResetButton__Q7z8b:active,
.HistoricalWeather_ResetButton__Q7z8b:focus {
    padding-left: 20px;
    cursor: pointer;
    color: #2d2d31;
    background-color: inherit;
    border: none;
}

.HistoricalWeather_Location__9RiJ0 {
    color: inherit;
    font-size: 1rem;
}

.HistoricalWeather_ChooseDate__MjgDa {
    font-size: 1.2rem;
    padding: 2rem;
}

@media only screen and (min-height: 1000px) {
    .HistoricalWeather_Section__lPRjb {
        padding-top: 5rem;
    }
}

@media only screen and (max-height: 590px) {
    .HistoricalWeather_Icon__7Wp3W {
        height: 70px;
    }

    .HistoricalWeather_Conditions__W9zOv {
        font-weight: 600;
        font-size: 1rem;
    }
}`, "",{"version":3,"sources":["webpack://./src/styles/HistoricalWeather.module.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,mBAAmB;AACvB;;AAEA;IACI,gBAAgB;IAChB,eAAe;AACnB;;AAEA;IACI,YAAY;IACZ,uBAAuB;AAC3B;;AAEA;IACI,YAAY;IACZ,sBAAsB;AAC1B;;AAEA;IACI,kBAAkB;IAClB,oBAAoB;IACpB,YAAY;IACZ,mBAAmB;IACnB,mBAAmB;AACvB;;AAEA;IACI,oBAAoB;AACxB;;AAEA;;;;;IAKI,kBAAkB;IAClB,eAAe;IACf,cAAc;IACd,yBAAyB;IACzB,YAAY;AAChB;;AAEA;IACI,cAAc;IACd,eAAe;AACnB;;AAEA;IACI,iBAAiB;IACjB,aAAa;AACjB;;AAEA;IACI;QACI,iBAAiB;IACrB;AACJ;;AAEA;IACI;QACI,YAAY;IAChB;;IAEA;QACI,gBAAgB;QAChB,eAAe;IACnB;AACJ","sourcesContent":[".ConditionsContainer {\n    width: auto;\n    align-items: center;\n}\n\n.Conditions {\n    font-weight: 600;\n    font-size: 1rem;\n}\n\n.InputContainer {\n    width: 19rem;\n    justify-content: center;\n}\n\n.InputSearch {\n    border: none;\n    background-color: #fff;\n}\n\n.Input {\n    text-align: center;\n    padding-left: 0.1rem;\n    border: none;\n    border-radius: 10px;\n    border-width: 0.5px;\n}\n\n.InputDate {\n    padding: .3rem .1rem;\n}\n\n.ResetButton,\n.ResetButton:visited,\n.ResetButton:hover,\n.ResetButton:active,\n.ResetButton:focus {\n    padding-left: 20px;\n    cursor: pointer;\n    color: #2d2d31;\n    background-color: inherit;\n    border: none;\n}\n\n.Location {\n    color: inherit;\n    font-size: 1rem;\n}\n\n.ChooseDate {\n    font-size: 1.2rem;\n    padding: 2rem;\n}\n\n@media only screen and (min-height: 1000px) {\n    .Section {\n        padding-top: 5rem;\n    }\n}\n\n@media only screen and (max-height: 590px) {\n    .Icon {\n        height: 70px;\n    }\n\n    .Conditions {\n        font-weight: 600;\n        font-size: 1rem;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"ConditionsContainer": `HistoricalWeather_ConditionsContainer__FjX0J`,
	"Conditions": `HistoricalWeather_Conditions__W9zOv`,
	"InputContainer": `HistoricalWeather_InputContainer__13Tdf`,
	"InputSearch": `HistoricalWeather_InputSearch__L6jFR`,
	"Input": `HistoricalWeather_Input__CMhXo`,
	"InputDate": `HistoricalWeather_InputDate__EIdN7`,
	"ResetButton": `HistoricalWeather_ResetButton__Q7z8b`,
	"Location": `HistoricalWeather_Location__9RiJ0`,
	"ChooseDate": `HistoricalWeather_ChooseDate__MjgDa`,
	"Section": `HistoricalWeather_Section__lPRjb`,
	"Icon": `HistoricalWeather_Icon__7Wp3W`
};
export default ___CSS_LOADER_EXPORT___;
